import React from "react";
import { useTranslation } from 'react-i18next';

const Services = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation();

  // services details
  const services = [
    {
      name: t("University Application Services"),
      desc: t("We provide guidance and support for clients who want to study abroad in Canada or Europe, including assistance with choosing universities and programs, completing application forms, writing personal statements, and tracking application status."),
      icon: "fas fa-school",
    },
    {
      name: t("Visa Application Services"),
      desc: t("We offer help for clients who need a visa to study or work abroad, including guidance on navigating the visa application process, filling out application forms, gathering necessary documents, and tracking application status."),
      icon: "fas fa-id-card",
    },
    {
      name: t("Writing Motivation Letters"),
      desc: t("We provide writing services for clients who need to submit a motivation letter as part of their university or job applications, including drafting, editing, and tailoring the letter to the specific requirements of the application."),
      icon: "fas fa-pencil-ruler",
    },
    {
      name: t("Visa Status Change"),
      desc: t("We provide assistance for clients who need to change their visa status, such as transitioning from tourist to student visa or obtaining a work permit. We offer guidance on the required documentation, application process, and other necessary steps to successfully change the visa status."),
      icon: "fas fa-arrows-rotate",
    },
    {
      name: t("Pre-Departure Orientation"),
      desc: t("We provide guidance and advice on preparing for life abroad, including understanding cultural differences and customs, finding accommodation and essential services, and understanding university rules and regulations."),
      icon: "fas fa-compass",
    },
    {
      name: t("Career Counselling and Job Placement"),
      desc: t("We offer assistance with exploring career options, finding employment opportunities, developing resumes and CVs, improving interview skills, and navigating the job search process for clients who want to study abroad with the goal of securing employment after graduation."),
      icon: "fas fa-suitcase",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            {t("Services")}
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {t("What we Do?")}
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
