import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Home": "Home",
      "About": "About",
      "What We Do": "What We Do",
      "Destinations": "Destinations",
      "Portfolio": "Portfolio",
      "Client": "Client",
      "Contact": "Contact",
      "About Us": "About Us",
      "WHERE":"WHERE",
      "Success rate":"Success rate",
      "Know Us More": "Know Us More",
      "We're B&M Consulting leaders in international mobility": "We're B&M Consulting leaders in international mobility",
      "What we Do?":"What we Do?",
      "At B&M, we are dedicated to helping individuals and families from around the world make a successful transition to life in a new country. Whether you're a student, professional, or entrepreneur, we provide personalized support and guidance to help you navigate the complex process of immigration.": "At B&M, we are dedicated to helping individuals and families from around the world make a successful transition to life in a new country. Whether you're a student, professional, or entrepreneur, we provide personalized support and guidance to help you navigate the complex process of immigration.",
      "team":"Our team of experienced professionals offers a range of services to assist with various aspects of the immigration process.",
      "Years Experience": "Years Experience",
      "Happy Clients": "Happy Clients",
      "Countries Served": "Countries Served",
      "Awards Received": "Awards Received",
      "Download Brochure" :"Download Brochure",
      "office 1:" :"office 1:",
      "office 2:" :"office 2:",
      "Stuttgart, Germany":"Stuttgart, Germany",
      "based in Montreal, Canada.":"based in Montreal, Canada.",
      "University Admissions": "University Admissions",
      "Visa Applications": "Visa Applications",
      "Bursary Obtention Success Rate": "Bursary Obtention Success Rate",
      "Job Placement Success Rate": "Job Placement Success Rate",
      "Wellcome":"welcome",
      "CANADA - title": "CANADA",
      "CANADA - desc": "Canada has programs like Express Entry for skilled workers and Study Permit for students. The process involves creating an online profile, being invited to apply, and submitting an application.",
      "GERMANY - title": "GERMANY",
      "GERMANY - desc": "Germany offers immigration routes for skilled workers and students. The process involves securing a job offer, obtaining recognition of qualifications, and submitting an application.",
      "SPAIN - title": "SPAIN",
      "SPAIN - desc": "Spain provides avenues for work permits, student visas, and family reunification. The process involves fulfilling specific requirements and submitting the necessary documentation.",
      "UNITED STATES (US) - title": "UNITED STATES (US)",
      "UNITED STATES (US) - desc": "The US offers employment-based visas like H-1B and family-based visas. The process involves petitioning through an employer or family member, submitting forms, and attending interviews.",
      "EUROPEAN UNION (EU) - title": "EUROPEAN UNION (EU)",
      "EUROPEAN UNION (EU) - desc": "The EU provides immigration options based on purpose and duration of stay. This includes the EU Blue Card for highly skilled professionals and Schengen/Student Visas for students.",
      "FRANCE - title": "FRANCE",
      "FRANCE - desc": "France offers immigration programs like the Talent Passport for skilled workers and Student Visa for students. The process involves submitting an application, providing required documents, and meeting specific requirements.",
      //... add the rest of your English translations here
      "Talk with us":"Register",
      "Get in Touch":"Get in Touch",
      "SEND US A NOTE" :"SEND US A NOTE",
      "ADDRESS":"ADDRESS",
      "FOLLOW US":"FOLLOW US",
      "Send Message": "Send Message",
      "Testimonial": "Testimonial",
      "Client Speak":"Client Speak",
      "University Application Services": "University Application Services",
    "We provide guidance and support for clients who want to study abroad in Canada or Europe, including assistance with choosing universities and programs, completing application forms, writing personal statements, and tracking application status.":
      "We provide guidance and support for clients who want to study abroad in Canada or Europe, including assistance with choosing universities and programs, completing application forms, writing personal statements, and tracking application status.",
    "Visa Application Services": "Visa Application Services",
    "We offer help for clients who need a visa to study or work abroad, including guidance on navigating the visa application process, filling out application forms, gathering necessary documents, and tracking application status.":
      "We offer help for clients who need a visa to study or work abroad, including guidance on navigating the visa application process, filling out application forms, gathering necessary documents, and tracking application status.",
    "Writing Motivation Letters": "Writing Motivation Letters",
    "We provide writing services for clients who need to submit a motivation letter as part of their university or job applications, including drafting, editing, and tailoring the letter to the specific requirements of the application.":
      "We provide writing services for clients who need to submit a motivation letter as part of their university or job applications, including drafting, editing, and tailoring the letter to the specific requirements of the application.",
      "Visa Status Change": "Visa Status Change",
      "We provide assistance for clients who need to change their visa status, such as transitioning from tourist to student visa or obtaining a work permit. We offer guidance on the required documentation, application process, and other necessary steps to successfully change the visa status.":
        "We provide assistance for clients who need to change their visa status, such as transitioning from tourist to student visa or obtaining a work permit. We offer guidance on the required documentation, application process, and other necessary steps to successfully change the visa status.",
      // ...
    "Pre-Departure Orientation": "Pre-Departure Orientation",
    "We provide guidance and advice on preparing for life abroad, including understanding cultural differences and customs, finding accommodation and essential services, and understanding university rules and regulations.":
      "We provide guidance and advice on preparing for life abroad, including understanding cultural differences and customs, finding accommodation and essential services, and understanding university rules and regulations.",
    "Career Counselling and Job Placement": "Career Counselling and Job Placement",
    "We offer assistance with exploring career options, finding employment opportunities, developing resumes and CVs, improving interview skills, and navigating the job search process for clients who want to study abroad with the goal of securing employment after graduation.":
      "We offer assistance with exploring career options, finding employment opportunities, developing resumes and CVs, improving interview skills, and navigating the job search process for clients who want to study abroad with the goal of securing employment after graduation.",
    // ...
  
    }
  },
  fr: {
    translation: {
      "Home": "Accueil",
      "About": "À propos",
      "What We Do": "Ce que nous faisons",
      "Destinations": "Destinations",
      "Portfolio": "Portfolio",
      "Client": "Client",
      "Contact": "Contact",
      "About Us": "À propos de nous",
      "Know Us More": "En savoir plus sur nous",
      "What we Do?":" Que Faisons-Nous?",
      "WHERE":"OÙ",
      "We're B&M Consulting leaders in international mobility": "Nous sommes les leaders de B&M Consulting en mobilité internationale",
      "At B&M, we are dedicated to helping individuals and families from around the world make a successful transition to life in a new country. Whether you're a student, professional, or entrepreneur, we provide personalized support and guidance to help you navigate the complex process of immigration.": "Chez B&M, nous nous engageons à aider les individus et les familles du monde entier à réussir leur transition vers une nouvelle vie dans un nouveau pays. Que vous soyez étudiant, professionnel ou entrepreneur, nous offrons un soutien et des conseils personnalisés pour vous aider à naviguer dans le processus complexe de l'immigration.",
      "team": "Notre équipe de professionnels expérimentés propose une vaste gamme de services pour vous aider dans votre  processus d'immigration.",
       "Years Experience": "Années d'expérience",
       "10 Countries": "10 Pays",
       "Happy Clients": "Clients satisfaits",
       "Countries Served": "Pays desservis",
       "Awards Received": "Récompenses reçues",
       "Download Brochure" :"Télécharger la Brochure",
       "Success rate":"Taux de Succes",
       "office 1:" :"bureau 1:",
       "office 2:" :"bureau 2:",
       "Stuttgart, Germany":"Stuttgart, Allemagne",
       "based in Montreal, Canada.":"basé a Montreal, Canada.",
       "University Admissions": "Admissions universitaires",
       "Visa Applications": "Demandes de visa",
       "Bursary Obtention Success Rate": "Taux de réussite d'obtention de bourse",
       "Job Placement Success Rate": "Taux de placement professionnel",
       "Wellcome":"Bienvenue",
       "CANADA - title": "CANADA",
       "CANADA - desc": "Le Canada propose des programmes tels que l'Entrée express pour les travailleurs qualifiés et le Permis d'études pour les étudiants. Le processus implique la création d'un profil en ligne, l'invitation à postuler et la soumission d'une demande.",
       "GERMANY - title": "ALLEMAGNE",
       "GERMANY - desc": "L'Allemagne offre des voies d'immigration pour les travailleurs qualifiés et les étudiants. Le processus implique de trouver une offre d'emploi, d'obtenir la reconnaissance des qualifications et de soumettre une demande.",
       "SPAIN - title": "ESPAGNE",
       "SPAIN - desc": "L'Espagne offre des possibilités de permis de travail, de visas étudiants et de regroupement familial. Le processus implique de remplir des exigences spécifiques et de soumettre la documentation nécessaire.",
       "UNITED STATES (US) - title": "ÉTATS-UNIS (US)",
       "UNITED STATES (US) - desc": "Les États-Unis offrent des visas basés sur l'emploi tels que le H-1B et des visas basés sur la famille. Le processus implique de faire une pétition auprès d'un employeur ou d'un membre de la famille, de soumettre des formulaires et de participer à des entretiens.",
       "EUROPEAN UNION (EU) - title": "UNION EUROPÉENNE (UE)",
       "EUROPEAN UNION (EU) - desc": "L'Union européenne propose des options d'immigration en fonction du but et de la durée du séjour. Cela inclut la Carte bleue européenne pour les professionnels hautement qualifiés et les visas Schengen/étudiants pour les étudiants.",
       "FRANCE - title": "FRANCE",
       "FRANCE - desc": "La France propose des programmes d'immigration tels que le Passeport Talent pour les travailleurs qualifiés et le Visa étudiant pour les étudiants. Le processus implique de soumettre une demande, de fournir les documents requis et de respecter des exigences spécifiques.",
       "Talk with us":" S'Inscrire",
       "Get in Touch":"Nous Rejoindre",
       "SEND US A NOTE" :"NOTIFIER NOUS",
        "ADDRESS":"ADRESSE",
        "FOLLOW US":"NOUS SUIVRE",
        "Send Message": "Envoyer le Message",
        "Testimonial":"Témoignages",
        "Client Speak":"Opinions des Clients",
       "University Application Services": "Services de demande d'université",
    "We provide guidance and support for clients who want to study abroad in Canada or Europe, including assistance with choosing universities and programs, completing application forms, writing personal statements, and tracking application status.":
      "Nous offrons des conseils et un soutien aux clients qui souhaitent étudier à l'étranger au Canada ou en Europe, y compris une assistance pour choisir des universités et des programmes, remplir des formulaires de candidature, rédiger des déclarations personnelles et suivre l'état de leur candidature.",
    "Visa Application Services": "Services de demande de visa",
    "We offer help for clients who need a visa to study or work abroad, including guidance on navigating the visa application process, filling out application forms, gathering necessary documents, and tracking application status.":
      "Nous aidons les clients qui ont besoin d'un visa pour étudier ou travailler à l'étranger, y compris des conseils sur le processus de demande de visa, le remplissage des formulaires de demande, la collecte des documents nécessaires et le suivi de l'état de la demande.",
    "Writing Motivation Letters": "Services de rédaction de lettres de motivation",
    "We provide writing services for clients who need to submit a motivation letter as part of their university or job applications, including drafting, editing, and tailoring the letter to the specific requirements of the application.":
      "Nous proposons des services d'écriture pour les clients qui doivent soumettre une lettre de motivation dans le cadre de leurs candidatures universitaires ou professionnelles, comprenant la rédaction, la correction et l'adaptation de la lettre aux exigences spécifiques de la candidature.",
      "Visa Status Change": "Changement de statut de visa",
      "Nous fournissons une assistance aux clients qui ont besoin de changer leur statut de visa, comme passer d'un visa de touriste à un visa d'étudiant ou obtenir un permis de travail. Nous offrons des conseils sur la documentation requise, le processus de demande et les autres étapes nécessaires pour changer avec succès le statut de visa.":
        "Nous fournissons une assistance aux clients qui ont besoin de changer leur statut de visa, comme passer d'un visa de touriste à un visa d'étudiant ou obtenir un permis de travail. Nous offrons des conseils sur la documentation requise, le processus de demande et les autres étapes nécessaires pour changer avec succès le statut de visa.",
      "Pre-Departure Orientation": "Orientation avant le départ",
      "We provide guidance and advice on preparing for life abroad, including understanding cultural differences and customs, finding accommodation and essential services, and understanding university rules and regulations.":
        "Nous offrons des conseils et des conseils pour se préparer à la vie à l'étranger, notamment pour comprendre les différences culturelles et les coutumes, trouver un logement et des services essentiels, et comprendre les règles et règlements universitaires.",
      "Career Counselling and Job Placement": "Orientation professionnelle et placement",
      "We offer assistance with exploring career options, finding employment opportunities, developing resumes and CVs, improving interview skills, and navigating the job search process for clients who want to study abroad with the goal of securing employment after graduation.":
        "Nous offrons une assistance pour explorer les options de carrière, trouver des opportunités d'emploi, élaborer des CV et des lettres de motivation, améliorer les compétences en entretien et naviguer dans le processus de recherche d'emploi pour les clients qui souhaitent étudier à l'étranger.",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
