import React from "react";
import { useTranslation } from 'react-i18next';
import resumeFile from "../documents/B&M.pdf";

const AboutUs = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation();

  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24 text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            {t("About Us")}
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {t("Know Us More")}
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        <div className="row gy-5">
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              {t("We're B&M Consulting leaders in international mobility")}
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              {t("At B&M, we are dedicated to helping individuals and families from around the world make a successful transition to life in a new country. Whether you're a student, professional, or entrepreneur, we provide personalized support and guidance to help you navigate the complex process of immigration.")}
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            {t("team")}
            </p>
          </div>
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">{t("B&M Consulting")}</span>
                </li>
                <li>
                  <span className="fw-600 me-2">{t("Email:")}</span>
                  <a href="mailto:firm.bnm@gmail.com">{t("firm.bnm@gmail.com")}</a>
                </li>
                <li>
                  <span className="fw-600 me-2">{t("office 1:")}</span>{t("Montreal, Canada")}
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">{t("office 2:")}</span>{t("Stuttgart, Germany")}
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                {t("Download Brochure")}
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{t("10")}</span>{t("+")}
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t("Years Experience")}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{t("250")}</span>{t("+")}
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t("Happy Clients")}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{t("13")}</span>{t("+")}
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t("Countries Served")}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{t("8")}</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t("Awards Received")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
