import React from "react";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation();
  const reviews = [
    {
      name: "Ghilas de Tizi Ouzou, Algerie",
      position: "Etudiant",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "Choisir B&M Consulting a été la meilleure décision que j'ai prise pour mon projet d'immigration. Leur équipe dévouée m'a aidé à obtenir un visa étudiant pour l'Espagne, rendant le processus efficace et sans stress. Je suis reconnaissant pour leur expertise et je recommande vivement leurs services.",
      rating: 5,
    },
    {
      name: "Chaima from Casablanca, Morocco",
      position: "Student",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "I can't thank B&M Consulting enough for their outstanding immigration services. They guided me through the complex visa application process and helped me secure a work permit in Germany. Their professionalism and expertise are unmatched. I highly recommend them!",
      rating: 5,
    },
    {
      name: "Marouane de Settat, Maroc",
      position: "Ingenieur en logiciels",
      src: "images/testimonial/client-sm-2.jpg",
      desc: "B&M Consulting est exceptionnel ! Ils ont rendu mon processus d'immigration extremement simple. Merci specialement a Nora qui m'a apporté un soutien personnalisé à chaque étape. Grâce à leurs conseils experts et à leur connaissance approfondie du système d'immigration canadien, j'ai pu obtenir mon visa rapidement et en toute confiance",
      rating: 5,
    },
    {
      name: "Gorav from pune india",
      position: "Student",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "B&M Consulting has been instrumental in making my dream of studying engineering in the USA a reality. Their expertise and guidance throughout the entire immigration process were exceptional. From helping me with my student visa application to providing valuable advice on universities and scholarships, they went above and beyond to ensure my success.",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-2" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            {t("Testimonial")}
            
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            {t("Client Speak")}
            
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
